<template>
  <b-btn @click.prevent="viewFileFromUrl()">
    Скачать
  </b-btn>
</template>
<script>
import {downloadFile, getFile} from '../services/api';
export default {
  name: 'FileViewer',
  props: ['fileGuid'],
  mounted() {
    this.viewFileFromUrl();
  },
  methods: {
    async viewFileFromUrl() {
      const response = await getFile(this.fileGuid);
      console.log(response);
      downloadFile(response.data);
    },
  },

};
</script>
